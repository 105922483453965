<template>
    <section class="almacen-materiales-compras px-3">
        <titulo-divisor titulo="Ordenes de compra" class="my-4">
            <div class="row">
                <div class="col auto">
                    <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar" size="small" />
                </div>
                <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="handleClearFilter"/>
            </div>
        </titulo-divisor>
        <tabla-general 
            :usar-servidor="false" 
            :usar-paginacion="false"
            :mostrarBuscador="false" 
            altura-tabla="calc(100vh - 390px)" 
            :data="compras"
        >
            <el-table-column label="Orden" prop="orden" align="center" >
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos f-600 cr-pointer" @click="verOrdenCompra(scope.row.id)">{{ scope.row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Proyecto" prop="proyecto">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.proyecto }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Proveedor" prop="proveedor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.proveedor }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cliente" prop="cliente">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.cliente }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades" prop="unidades" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.unidades }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha" prop="fecha" align="center"  />
            <el-table-column label="Valor" prop="valor" align="center">
                <template slot-scope="scope">
                    {{ formatoMoneda(scope.row.valor) }}
                </template>
            </el-table-column>
            <el-table-column label="Pago" prop="pago"  />
        </tabla-general>
        <div class="row justify-content-center">
            <div class="col-auto">
                <Pagination :pagination="pagination" layout="prev, pager, next" @paginate="Action_get_compras(id_material)"/>
            </div>
        </div>
         <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Proyecto</p>
                    <el-select v-model="filter.id_proyecto" placeholder="Seleccionar proyecto" size="small" class="w-100">
                        <el-option v-for="item in filtro_compras && filtro_compras.proyectos" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Proveedores</p>
                    <el-select v-model="filter.id_proveedor" placeholder="Seleccionar proveedores" size="small" class="w-100" multiple collapse-tags>
                        <el-option v-for="item in filtro_compras && filtro_compras.proveedores" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Cliente</p>
                    <el-select v-model="filter.id_cliente" placeholder="Seleccionar cliente" size="small" class="w-100">
                        <el-option v-for="item in filtro_compras && filtro_compras.clientes" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-6 my-3">
                    <p class="input-label-top">Fecha de inicio</p>
                    <el-date-picker v-model="filter.fecha_min" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                <div class="col-6 my-3">
                    <p class="input-label-top">Fecha final</p>
                    <el-date-picker v-model="filter.fecha_max" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de unidades</p>
                    <el-slider v-model="range_unidades" range :min="filtro_compras.unidades_min" :max="filtro_compras.unidades_max" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_valor" range :min="filtro_compras.valor_min" :max="filtro_compras.valor_max" :format-tooltip="formatTooltipRange" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Estado de pago</p>
                    <el-select v-model="filter.id_estado" placeholder="Seleccionar estado" size="small" class="w-100">
                        <el-option v-for="item in filtro_compras && filtro_compras.estados" :key="item.id" :label="item.estado" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="handleClearFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
    
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
        }
    },
    computed: {
        ...mapGetters({
            compras: 'almacen/compras/compras',
            filtro_compras: 'almacen/compras/filtro_compras',
        }),
        filter: {
            get(){
                return this.$store.getters['almacen/compras/filter']
            }, 
            set(val){
                this.$store.commit('almacen/compras/set_filter', val)
            }
        },
        pagination: {
            get(){
                return this.$store.getters['almacen/compras/pagination']
            }, 
            set(val){
                this.$store.commit('almacen/compras/set_pagination', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['almacen/compras/query']
            }, 
            set(val){
                this.$store.commit('almacen/compras/set_query', val)
            }
        },
        range_unidades: {
            get(){
                return [
                    this.filter.unidades_min,
                    this.filter.unidades_max
                ]
            },
            set(val){
                this.filter.unidades_min = val[0]
                this.filter.unidades_max = val[1]
            }
        },
        range_valor: {
            get(){
                return [
                    this.filter.valor_min,
                    this.filter.valor_max
                ]
            },
            set(val){
                this.filter.valor_min = val[0]
                this.filter.valor_max = val[1]
            }
        },
        filterCount(){
            return this.filter.count()
        },
        id_material(){
            return this.$route.params.id
        }
    },
    async created () {
        await this.Action_get_filtro_compras(this.id_material)
        this.clearFilter()
        await this.Action_get_compras(this.id_material)

    },
    methods:{
        ...mapActions({
            Action_get_compras: 'almacen/compras/Action_get_compras',
            Action_get_filtro_compras: 'almacen/compras/Action_get_filtro_compras',
            clearFilter: 'almacen/compras/Action_clear_filter',
        }),
        beforeSearch(){
            this.Action_get_compras(this.id_material)
        },
        handleClearFilter(){
            this.clearFilter()
            this.Action_get_compras(this.id_material)
        },
        verOrdenCompra(id){
            this.$router.push({ name: 'compras.ver.orden', params:{ id_orden : id } })
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async filtrarMateriales(){
            await this.Action_get_compras(this.id_material)
            this.$refs.abrirModalFiltros.toggle()
        },
    }

}
</script>

<style lang="scss" scoped>
.almacen-materiales-compras{
    .img-material{
        width: 30;
    }
    .rotate-360{
        transform: rotate( 180deg );
    }
   
}
</style>